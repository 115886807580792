import React from 'react';
import Link from 'next/link';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { colors } from '@/lib/theme/palette';
import ResourceInterface from '@/shared/apis/resources/types.resources.d';
import { SxProps } from '@mui/system';
// import Image from 'next/image';

type Props = {
  resource: ResourceInterface;
  disbleRowResponsive?: boolean;
  loading?: boolean;
  sx?: React.CSSProperties;
};

const Badge = ({
  children,
  prevent,
  sx,
}: {
  children: React.ReactNode;
  prevent?: React.ReactNode;
  sx?: SxProps;
}) => (
  <Box
    padding={'4px 16px'}
    bgcolor={'rgba(241, 243, 255, 0.5)'}
    sx={{
      backdropFilter: 'blur(8px)',
      position: 'absolute',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      zIndex: 3,
      ...sx,
    }}
  >
    {prevent && prevent}

    <Typography
      color={colors.blue}
      variant="caption4"
      fontSize={{
        xs: '11px',
        sm: '12px',
      }}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
      }}
    >
      {children}
    </Typography>
  </Box>
);

export default function ResourceCard(props: Props) {
  const { resource, sx, loading, disbleRowResponsive } = props;
  const { keywords } = resource;

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        border: 0,
        borderRadius: '8px',
        position: 'relative',
        '&:hover': {
          boxShadow: '0px 16px 20px -12px rgba(106, 135, 255, 0.2)',
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          background: colors.azureish,
          maxHeight: {
            xs: 164,
            sm: 198,
            md: 280,
          },
          position: 'relative',
          flex: {
            xs: '1 0 auto',
            sm: 'auto',
          },
        }}
      >
        {loading ? (
          <Skeleton variant="rounded" width="100%" height={320} />
        ) : (
          <Link
            href={`/resource/${resource.id}`}
            prefetch
            style={{ display: 'block' }}
          >
            <Badge
              sx={{
                display: 'flex',
                alignItems: 'center',
                top: 0,
                left: 0,
              }}
              prevent={
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={{
                    xs: '16px',
                    sm: '24px',
                  }}
                  height={{
                    xs: '16px',
                    sm: '24px',
                  }}
                  mr={'8px'}
                  borderRadius={'50%'}
                  overflow={'hidden'}
                >
                  <CardMedia
                    component="img"
                    image={
                      resource.company?.image_path || '/company-default-img.png'
                    }
                    width={24}
                    height={24}
                    alt={resource.company.name}
                    sx={{
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              }
            >
              <Typography
                variant="caption1"
                sx={{
                  color: colors.black700,
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  whiteSpace: 'pre-wrap',
                  fontWeight: 500,
                }}
              >
                {resource.company?.name || 'No company name'}
              </Typography>
            </Badge>

            <Badge
              sx={{
                transform: 'translateY(-50%)',
                top: '100%',
                left: 0,
              }}
            >
              {resource.resource_type}
            </Badge>

            <Box
              sx={{
                margin: '0 auto',
                height: {
                  xs: 164,
                  sm: 198,
                  md: 280,
                },
                maxHeight: '100%',
                position: 'relative',
              }}
            >
              <CardMedia
                component="img"
                alt={resource?.name || 'Title'}
                sx={{
                  overflow: 'hidden',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  margin: '0 auto',
                }}
                image={
                  resource?.source_main_img_url || '/resource-empty-picture.svg'
                }
              />
            </Box>
          </Link>
        )}
      </Box>
      <Box
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <CardContent
          sx={{
            padding: {
              xs: '16px 16px 8px',
              sm: '20px 20px 24px',
            },
            paddingBottom: {
              xs: '8px !important',
              sm: '24px',
            },
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {loading ? (
            <Skeleton
              variant="rounded"
              width="100%"
              height={60}
              sx={{ mb: 1.5 }}
            />
          ) : (
            <Link href={`/resource/${resource.id}`}>
              <Typography
                component="h4"
                variant="h4"
                fontWeight={700}
                title={resource?.name || 'Title'}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: {
                    xs: 2,
                    sm: 3,
                    md: 4,
                  },
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  transition: 'color 0.2s ease-in-out',
                  '&:active': {
                    color: colors.blue,
                  },
                }}
              >
                {resource.name || 'Title'}
              </Typography>
            </Link>
          )}
          {loading ? (
            <Skeleton variant="rounded" width="100%" height={20} />
          ) : (
            <Box
              sx={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                paddingTop: 2,
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                whiteSpace: 'pre-wrap',
                marginTop: 'auto',
                wordBreak: 'break-word',
                width: {
                  xs: disbleRowResponsive ? '49vw' : '100%',
                  sm: '100%',
                },
                lineHeight: {
                  xs: '20px',
                  sm: 'auto',
                },
              }}
            >
              {keywords.map((keyword: string) => {
                return (
                  <Box
                    key={`keyword_${keyword}`}
                    component="span"
                    sx={{
                      '&:after': {
                        content: "'•'",
                        color: colors.black700,
                        padding: '0 4px',
                        fontSize: '14px',
                      },
                      '&:last-child': {
                        '&:after': {
                          content: "''",
                        },
                      },
                    }}
                  >
                    <Link href={`/library?type=resources&keywords=${keyword}`}>
                      <Typography
                        component="span"
                        sx={{
                          color: colors.blue,
                          fontWeight: 500,
                          fontSize: {
                            xs: '12px',
                            sm: '14px',
                          },
                          wordBreak: 'keep-all',

                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {keyword}
                      </Typography>
                    </Link>
                  </Box>
                );
              })}
            </Box>
          )}
        </CardContent>
      </Box>
    </Card>
  );
}
