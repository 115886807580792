import httpClient from '@/lib/client/httpClient';
import ResourceInterface, {
  TFetchResources,
  TFetchResourcesParams,
} from '@/shared/apis/resources/types.resources';

export const FetchResources: TFetchResources = async params => {
  return await httpClient.get<
    TFetchResourcesParams,
    { data: ResourceInterface[]; total_items: number }
  >(`/pdf-resources`, {
    params,
  });
};

const methods = {
  FetchResources,
};

export default methods;
